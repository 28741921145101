<template>
  <div>
    <div class="form-group row required">
      <label class="col-sm-4 col-form-label text-right" for="txt_email">削除済ユーザーのメールアドレス</label>
      <div class="col-sm-8">
        <input type="email" name="email" id="txt_email" class="form-control" v-model="email" @keydown.enter.prevent>
      </div>
    </div>
    <button type="button" class="btn btn-info float-right" @click="openConfirmationModal()">
      確認
    </button>

    <div class="confirmation-modal-background" v-if="showConfirmationModal">
      <div class="confirmation-modal">
        <span class="close" @click="closeConfirmationModal()">×</span>
        <div class="confirmation-modal-wrapper" v-if="hasDeletedUserData()">
          <div class="confirmation-modal-header">
            <p>復元させるユーザーをご確認ください</p>
          </div>
          <div class="confirmation-modal-body">
            <div class="check-item">
              <input type="checkbox" class="checkbox" id="company_id_check" v-model="companyNameCheck">
              <label class="label" for="company_id_check">会社：</label>
              <p class="value">{{ deletedUserData.company_name }}</p>
            </div>
            <div class="check-item">
              <input type="checkbox" class="checkbox" id="email_check" v-model="emailCheck">
              <label class="label" for="email_check">メールアドレス：</label>
              <p class="value">{{ deletedUserData.email }}</p>
            </div>
            <div class="check-item">
              <input type="checkbox" class="checkbox" id="last_name_check" v-model="lastNameCheck">
              <label class="label" for="last_name_check">苗字：</label>
              <p class="value">{{ deletedUserData.last_name }}</p>
            </div>
            <div class="check-item">
              <input type="checkbox" class="checkbox" id="first_name_check" v-model="firstNameCheck">
              <label class="label" for="first_name_check">名前：</label>
              <p class="value">{{ deletedUserData.first_name }}</p>
            </div>
            <div class="buttons">
              <button type="button" class="btn btn-secondary" @click="closeConfirmationModal()">キャンセル</button>
              <button type="submit" class="btn btn-info" :class="{ disabled: !checkedAll() }" :disabled="!checkedAll()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                復元
              </button>
            </div>
          </div>
        </div>
        <div class="confirmation-modal-wrapper" v-else>
          <div class="alert alert-danger" role="alert" v-if="Object.keys(errorMessages).length">
            <ul><li v-for='(errorMessage, index) in errorMessages' :key="index">{{ errorMessage }}</li></ul>
          </div>
          <div class="confirmation-modal-body">
            <div class="buttons">
              <button type="button" class="btn btn-secondary" @click="closeConfirmationModal()">閉じる</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    getDeletedUserByEmailEndpoint: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      email: '',
      showConfirmationModal: false,
      errorMessages: [],
      deletedUserData: {},
      companyNameCheck: false,
      emailCheck: false,
      lastNameCheck: false,
      firstNameCheck: false
    }
  },

  watch: {
    companyNameCheck() {
      this.checkedAll()
    },
    emailCheck() {
      this.checkedAll()
    },
    lastNameCheck() {
      this.checkedAll()
    },
    firstNameCheck() {
      this.checkedAll()
    }
  },

  methods: {
    // 削除済ユーザー情報を取得した上で確認モーダルを開く
    async openConfirmationModal() {
      await this.getDeletedUserByEmail()
      this.showConfirmationModal = true
    },

    // 確認モーダルを閉じる
    closeConfirmationModal() {
      this.showConfirmationModal = false
      this.deletedUserData = {}
      this.errorMessages = []
    },

    // 入力されたメールアドレスから削除済ユーザー情報を取得
    async getDeletedUserByEmail() {
      await axios.get(this.getDeletedUserByEmailEndpoint, {
        params: {
          email: this.email,
        },
      })
      .then((response) => {
        this.deletedUserData = response.data
      })
      .catch((error) => {
        if (error.response.status === 422) {
          const errorList = []
          Object.keys(error.response.data.messages).forEach(function (key) {
            errorList.push(error.response.data.messages[key])
          })
          this.errorMessages = Object.assign({}, errorList.flat())
        }
        if (error.response.status === 500) {
          this.errorMessages.push(error.response.data.message)
        }
      })
    },

    // 全てにチェックが入っているか
    checkedAll() {
      return (
        this.companyNameCheck
        && this.emailCheck
        && this.lastNameCheck
        && this.firstNameCheck
      )
    },

    // 削除済ユーザー情報があるか
    hasDeletedUserData() {
      return Object.keys(this.deletedUserData).length > 0;
    }
  }
}
</script>

<style lang="scss" scoped>

.confirmation-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  > .confirmation-modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    padding: 60px;
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    position: relative;
    overflow-y: scroll;
    max-height: 90vh;

    > .confirmation-modal-wrapper {
      > .confirmation-modal-header {
        font-size: 28px;
        font-weight: 600;
        margin: 0 auto;
        padding-bottom: 15px;
        display: flex;
        justify-content: center;
      }

      > .confirmation-modal-body {
        margin-top: 20px;

        > .check-item {
          display: flex;
          padding: 20px;
          border-top: 2px solid #D9D9D9;

          &.-last {
            border-bottom: 2px solid #D9D9D9;
          }

          > .checkbox {
            height: 30px;
            width: 30px;
            cursor: pointer;
          }

          > .label {
            font-size: 18px;
            margin: 0;
            padding: 0 20px;
            line-height: 32px;
          }

          > .value {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            line-height: 32px;
          }
        }

        > .buttons {
          padding-top: 30px;
          display: flex;
          justify-content: space-around;

          > .btn {
            font-size: 18px;
          }
        }
      }
    }


    > .close {
      position: absolute;
      top: 1px;
      right: 8px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.disabled {
  background-color: #AAAAAA;
  border: 1px solid #AAAAAA;
  color: #7B7B7B;
}
</style>
