import './bootstrap';
import './backend/addAndDeleteFormInput';
import './backend/changeSelectOptionsByParentSelectOption';
import './backend/preventDoubleClick';

import { createApp } from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import UploadForm from './components/insurance_company_confirmation_document/UploadForm.vue';
import DisplayAndDeleteField from './components/insurance_company_confirmation_document/DisplayAndDeleteField.vue';
import SearchableSelectBoxFormGroup from './components/SearchableSelectBoxFormGroup.vue';
import UserCreateField from './components/user/CreateField.vue';
import UserEditField from './components/user/EditField.vue';
import UserRestoreField from './components/user/RestoreField.vue';
import CompanyCreateField from './components/company/CreateField.vue';
import CompanyEditField from './components/company/EditField.vue';
import InsuranceCompaniesTab from './components/company/InsuranceCompaniesTab.vue';
import InsuranceCompanyConfirmationUrlInputField from './components/insurance_company_confirmation_url/InputField.vue';
import InsuranceCompanyConfirmationUrlDisplayAndDeleteField from './components/insurance_company_confirmation_url/DisplayAndDeleteField.vue';
import CompanyCheckField from './components/CompanyCheckField.vue';
import ResultSortOrderForm from './components/Result/SortOrderForm.vue';
// アプリケーションインスタンスの作成
const app = createApp({
});

app.component('v-select', vSelect);
app.component('insurance-company-confirmation-document-upload-form', UploadForm);
app.component('insurance-company-confirmation-document-display-and-delete-field', DisplayAndDeleteField);
app.component('searchable-select-box-form-group', SearchableSelectBoxFormGroup);
app.component('user-create-field', UserCreateField);
app.component('user-edit-field', UserEditField);
app.component('user-restore-field', UserRestoreField);
app.component('company-create-field', CompanyCreateField);
app.component('company-edit-field', CompanyEditField);
app.component('insurance-companies-tab', InsuranceCompaniesTab);
app.component('insurance-company-confirmation-url-input-field', InsuranceCompanyConfirmationUrlInputField);
app.component('insurance-company-confirmation-url-display-and-delete-field', InsuranceCompanyConfirmationUrlDisplayAndDeleteField);
app.component('company-check-field', CompanyCheckField);
app.component('result-sort-order-form', ResultSortOrderForm);

// アプリケーションのマウント
app.mount('#app');
