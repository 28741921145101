(function ($) {
  var $children = $('.select-children')
  var original = $children.html()

  $('.select-parent').change(function () {
    var parentSelectedOption = $(this).val()

    $children.html(original).find('option').each(function () {
      var childrenDataVal = $(this).data('val')
      if (parentSelectedOption != childrenDataVal) {
        $(this).not('.select-none,.message').remove()
      }
    })

    if ($(this).val() === '') {
      $children.attr('disabled', 'disabled')
    } else {
      $children.removeAttr('disabled')
    }
  })
}) (window.jQuery)
