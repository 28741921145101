<template>
  <div>
    <SearchableSelectBoxFormGroup
      ref="searchableSelectBoxFormGroup"
      label-name="会社"
      name-attribute="company_id"
      :is-required="true"
      :option-data="companies"
      :old-code="oldCompanyId"
      :selected-value="selectedValue"
      select-box-class="col-sm-9"
    >
    </SearchableSelectBoxFormGroup>
    <div class="form-group row required">
      <label class="col-sm-2 col-form-label text-right" for="txt_email">メール</label>
      <div class="col-sm-9">
        <input type="email" name="email" id="txt_email" class="form-control" v-model="email">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label text-right" for="roles">ユーザーアドミン権限</label>
      <div class="col-sm-9">
        <select name="roles[]" id="roles" class="form-control" multiple v-model="selectedRoles">
          <option v-for="(role, index) in roles" :key="index" :value="role.name">
            {{ role.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-sm-2 col-form-label text-right" for="txt_last_name">苗字</label>
      <div class="col-sm-9">
        <input type="text" name="last_name" id="txt_last_name" class="form-control" v-model="lastName">
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-sm-2 col-form-label text-right" for="txt_first_name">名前</label>
      <div class="col-sm-9">
        <input type="text" name="first_name" id="txt_first_name" class="form-control" v-model="firstName">
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-sm-2 col-form-label text-right" for="txt_password">パスワード</label>
      <div class=" password-form col-sm-9">
        <input :type="showPassword ? 'text' : 'password'" name="password" id="txt_password" class="form-control" v-model="password">
        <span class="eye-icon" @click="switchShowPassword(true)" v-show="!showPassword">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
        </span>
        <span class="eye-off-icon" @click="switchShowPassword(false)" v-show="showPassword">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label text-right" for="txt_life_insurance_agent_id">募集人登録番号</label>
      <div class="col-sm-9">
        <input type="text" name="life_insurance_agent_id" id="txt_life_insurance_agent_id" class="form-control" v-model="lifeInsuranceAgentId">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label text-right" for="txt_non_life_insurance_agent_id">損保募集人ID</label>
      <div class="col-sm-9">
        <input type="text" name="non_life_insurance_agent_id" id="txt_non_life_insurance_agent_id" class="form-control" v-model="nonLifeInsuranceAgentId">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label text-right" for="departments">拠点</label>
      <div class="col-sm-9">
        <input type="text" name="departments" id="departments" class="form-control" v-model="departments">
        <span class="text-muted">※複数の拠点を登録する場合はカンマ「,」区切りで入力（例：営業部,仙台支店）</span>
      </div>
    </div>
    <button type="button" class="btn btn-info float-right" @click="switchConfirmationModal(true); checkEmailDomain()" :class="{ disabled: !isAllRequiredFieldsFilled }" :disabled="!isAllRequiredFieldsFilled">
      確認
    </button>

    <div class="confirmation-modal-background" v-if="showConfirmationModal">
      <div class="confirmation-modal">
        <span class="close" @click="switchConfirmationModal(false)">×</span>
        <div class="confirmation-modal-header">
          <p>入力内容をご確認ください</p>
        </div>
        <div class="alert alert-danger" role="alert" v-if="!isMatchedDomain">
          入力したメールアドレスのドメインと選択した会社の担当者のメールアドレスのドメインが一致していません。念の為ご確認ください。
        </div>
        <div class="alert alert-danger" role="alert" v-if="Object.keys(errorMessages).length">
          <ul><li v-for='(errorMessage, index) in errorMessages' :key="index">{{ errorMessage }}</li></ul>
        </div>
        <div class="confirmation-modal-body">
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="company_id_check" v-model="companyIdCheck">
            <label class="label" for="company_id_check">会社：</label>
            <p class="value">{{ this.$refs.searchableSelectBoxFormGroup.selectedOption.label }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="email_check" v-model="emailCheck">
            <label class="label" for="email_check">メール：</label>
            <p class="value">{{ email }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="roles_check" v-model="rolesCheck">
            <label class="label" for="roles_check">ユーザーアドミン権限：</label>
            <p class="value">{{ selectedRoles.length ?  'あり' : 'なし' }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="last_name_check" v-model="lastNameCheck">
            <label class="label" for="last_name_check">苗字：</label>
            <p class="value">{{ lastName }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="first_name_check" v-model="firstNameCheck">
            <label class="label" for="first_name_check">名前：</label>
            <p class="value">{{ firstName }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="life_insurance_agent_id_check" v-model="lifeInsuranceAgentIdCheck">
            <label class="label" for="life_insurance_agent_id_check">募集人登録番号：</label>
            <p class="value">{{ lifeInsuranceAgentId ? lifeInsuranceAgentId : 'なし' }}</p>
          </div>
          <div class="check-item">
            <input type="checkbox" class="checkbox" id="non_life_insurance_agent_id_check"  v-model="nonLifeInsuranceAgentIdCheck">
            <label class="label" for="non_life_insurance_agent_id_check">損保募集人ID：</label>
            <p class="value">{{ nonLifeInsuranceAgentId ? nonLifeInsuranceAgentId : 'なし' }}</p>
          </div>
          <div class="check-item -last">
            <input type="checkbox" class="checkbox" id="departments_check" v-model="departmentsCheck">
            <label class="label" for="departments_check">拠点：</label>
            <p class="value">{{ departments ? departments : 'なし' }}</p>
          </div>
          <div class="buttons">
            <button type="button" class="btn btn-secondary" @click="switchConfirmationModal(false)">キャンセル</button>
            <button type="submit" class="btn btn-info"  :class="{ disabled: !isAllChecked }" :disabled="!isAllChecked">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              登録
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SearchableSelectBoxFormGroup from '../SearchableSelectBoxFormGroup.vue'

export default {
  components: {
    SearchableSelectBoxFormGroup
  },

  props: {
    companies: {
      type: [Object, Array],
      required: true
    },
    roles: {
      type: [Object, Array],
      required: true
    },
    selectedValue: {
      type: Number,
      required: false
    },
    checkEmailDomainEndpoint: {
      type: String,
      required: true
    },
    oldEmail: {
      type: String,
      required: false
    },
    oldLifeInsuranceAgentId: {
      type: String,
      required: false
    },
    oldNonLifeInsuranceAgentId: {
      type: String,
      required: false
    },
    oldLastName: {
      type: String,
      required: false
    },
    oldFirstName: {
      type: String,
      required: false
    },
    oldDepartments: {
      type: String,
      required: false
    },
    oldCompanyId: {
      type: Number,
      required: false
    },
    oldRoles: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      showConfirmationModal: false,
      email: '',
      lifeInsuranceAgentId: '',
      nonLifeInsuranceAgentId: '',
      lastName: '',
      firstName: '',
      password: '',
      departments: '',
      selectedRoles: [],
      emailCheck: false,
      lifeInsuranceAgentIdCheck: false,
      nonLifeInsuranceAgentIdCheck: false,
      lastNameCheck: false,
      firstNameCheck: false,
      companyIdCheck: false,
      departmentsCheck: false,
      rolesCheck: false,
      isAllChecked: false,
      isAllRequiredFieldsFilled: false,
      showPassword: false,
      isMatchedDomain: true,
      errorMessages: []
    }
  },

  watch: {
    emailCheck() {
      this.checkAllChecked()
    },
    lifeInsuranceAgentIdCheck() {
      this.checkAllChecked()
    },
    nonLifeInsuranceAgentIdCheck() {
      this.checkAllChecked()
    },
    lastNameCheck() {
      this.checkAllChecked()
    },
    firstNameCheck() {
      this.checkAllChecked()
    },
    companyIdCheck() {
      this.checkAllChecked()
    },
    departmentsCheck() {
      this.checkAllChecked()
    },
    rolesCheck() {
      this.checkAllChecked()
    },
    email() {
      this.checkAllRequiredFieldsFilled()
    },
    lastName() {
      this.checkAllRequiredFieldsFilled()
    },
    firstName() {
      this.checkAllRequiredFieldsFilled()
    },
    password() {
      this.checkAllRequiredFieldsFilled()
    },
  },

  mounted() {
    // バリデーションエラー時の入力値保持
    if (this.oldEmail) {
      this.email = this.oldEmail
    }
    if (this.oldLifeInsuranceAgentId) {
      this.lifeInsuranceAgentId = this.oldLifeInsuranceAgentId
    }
    if (this.oldNonLifeInsuranceAgentId) {
      this.nonLifeInsuranceAgentId = this.oldNonLifeInsuranceAgentId
    }
    if (this.oldLastName) {
      this.lastName = this.oldLastName
    }
    if (this.oldFirstName) {
      this.firstName = this.oldFirstName
    }
    if (this.oldDepartments) {
      this.departments = this.oldDepartments
    }
    if (this.oldRoles) {
      this.selectedRoles = this.oldRoles
    }
  },

  methods: {
    switchConfirmationModal(bool) {
      this.showConfirmationModal = bool
    },

    // 全てにチェックが入っているか
    checkAllChecked() {
      if (
        this.emailCheck
        && this.lifeInsuranceAgentIdCheck
        && this.nonLifeInsuranceAgentIdCheck
        && this.lastNameCheck
        && this.firstNameCheck
        && this.companyIdCheck
        && this.departmentsCheck
        && this.rolesCheck
      ) {
        this.isAllChecked = true
      } else {
        this.isAllChecked = false
      }
    },

    // 必須項目が全て入力されているか
    checkAllRequiredFieldsFilled() {
      if (
        this.email
        && this.lastName
        && this.firstName
        && this.password
        && this.$refs.searchableSelectBoxFormGroup.selectedOption.code
      ) {
        this.isAllRequiredFieldsFilled = true
      } else {
        this.isAllRequiredFieldsFilled = false
      }
    },

    switchShowPassword(bool) {
      this.showPassword = bool
    },

    // 入力したメールアドレスのドメインと選択した会社の担当者のメールアドレスのドメインが一致しているかをチェック
    checkEmailDomain() {
      this.errorMessages = []
      axios.get(this.checkEmailDomainEndpoint, {
        params: {
          email: this.email,
          company_id: this.$refs.searchableSelectBoxFormGroup.selectedOption.code
        }
      }).then((response) => {
        this.isMatchedDomain = response.data.result
      }).catch((error) => {
        if (error.response.status === 422) {
          const errorList = []
          Object.keys(error.response.data.messages).forEach(function (key) {
            errorList.push(error.response.data.messages[key])
          })
          this.errorMessages = Object.assign({}, errorList.flat())
        }
        if (error.response.status === 500) {
          this.errorMessages.push(error.response.data.message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-form {
  position: relative;

  > .eye-icon,
  > .eye-off-icon {
    position: absolute;
    bottom: 5px;
    right: 20px;
    cursor: pointer;
  }
}

.confirmation-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  > .confirmation-modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    padding: 60px;
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    position: relative;
    overflow-y: scroll;
    max-height: 90vh;

    > .confirmation-modal-header {
      font-size: 28px;
      font-weight: 600;
      margin: 0 auto;
      padding-bottom: 15px;
      display: flex;
      justify-content: center;
    }

    > .confirmation-modal-body {
      margin-top: 20px;

      > .check-item {
        display: flex;
        padding: 20px;
        border-top: 2px solid #D9D9D9;

        &.-last {
          border-bottom: 2px solid #D9D9D9;
        }

        > .checkbox {
          height: 30px;
          width: 30px;
          cursor: pointer;
        }

        > .label {
          font-size: 18px;
          margin: 0;
          padding: 0 20px;
          line-height: 32px;
        }

        > .value {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
          line-height: 32px;
        }
      }

      > .buttons {
        padding-top: 30px;
        display: flex;
        justify-content: space-around;

        > .btn {
          font-size: 18px;
        }
      }
    }

    > .close {
      position: absolute;
      top: 1px;
      right: 8px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.disabled {
  background-color: #AAAAAA;
  border: 1px solid #AAAAAA;
  color: #7B7B7B;
}
</style>
