<template>
  <div>
    <div class="form-group row" v-for="(insuranceCompanyConfirmationDocument, index) in insuranceCompanyConfirmationDocumentList" :key="index">
      <label for="txt_document" class="col-sm-2 col-form-label text-right">
        取扱保険会社の確認に利用したファイル{{ index + 1 }}<br>
        <small>※ファイル名の変更はできません</small>
      </label>
      <div class="col-sm-8 mt-3">
        <input type="text" id="txt_document" class="form-control" :value="insuranceCompanyConfirmationDocument.name" readonly>
      </div>
      <button type="button" class="btn btn-danger h-25 mt-3" @click="deleteDocument(insuranceCompanyConfirmationDocument, index)">
        削除
      </button>
    </div>
    <UploadForm
      :number-of-registered-documents="insuranceCompanyConfirmationDocumentList.length"
      :max-number-of-registrations="maxNumberOfRegistrations"
      :is-edit-page="true"
    >
    </UploadForm>
  </div>
</template>

<script>
import axios from 'axios'
import UploadForm from './UploadForm.vue'

export default {
  components: { UploadForm },

  props: {
    insuranceCompanyConfirmationDocuments: {
      type: Array,
      required: false
    },
    insuranceCompanyConfirmationDocumentDestroyEndpoint: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
    maxNumberOfRegistrations: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      insuranceCompanyConfirmationDocumentList: this.insuranceCompanyConfirmationDocuments
    }
  },

  methods: {
    // 引数に与えられた取扱保険会社の確認に利用したファイルを非同期で削除する
    deleteDocument(insuranceCompanyConfirmationDocument, index) {
      let answer = confirm(insuranceCompanyConfirmationDocument.name + 'を削除してもよろしいでしょうか？')

      if (!answer) {
        alert('キャンセルしました。')
        return 
      }
      
      axios.delete(this.insuranceCompanyConfirmationDocumentDestroyEndpoint, {
        data: {
          document_id: insuranceCompanyConfirmationDocument.id,
          _token: this.csrfToken,
        }
      }).then((response) => {
        this.insuranceCompanyConfirmationDocumentList.splice(index, 1)
        window.setTimeout(function() {
          alert(insuranceCompanyConfirmationDocument.name + 'を削除しました。')
        }, 500)
      }).catch((error) => {
        alert(error.response.data.message)
      })
    }
  },
}
</script>
