<template>
  <div>
    <div class="form-container">
      <div class="form-group row required">
        <label for="txt_name" class="col-sm-2 col-form-label text-right">企業名</label>
        <div class="col-sm-10">
          <input type="text" id="txt_name" class="form-control" name="name" v-model="inputs.name">
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="company_type_id">企業タイプ</label>
        <div class="col-sm-10">
          <select name="company_type_id" id="company_type_id" class="form-control" v-model="inputs.companyTypeId">
            <option disabled value="">選択してください</option>
            <option v-for="companyType in companyTypes" :key="companyType.id" :value="companyType.id">
              {{ companyType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="sales_model_id">種別</label>
        <div class="col-sm-10">
          <select name="sales_model_id" id="sales_model_id" class="form-control" v-model="inputs.salesModelId">
            <option disabled value="">選択してください</option>
            <option v-for="companySalesModel in companySalesModels" :key="companySalesModel.id" :value="companySalesModel.id">
              {{ companySalesModel.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_employee_number" class="col-sm-2 col-form-label text-right">従業員数</label>
        <div class="col-sm-10">
          <input type="text" id="txt_employee_number" class="form-control" name="employee_number" v-model="inputs.employeeNumber">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_email" class="col-sm-2 col-form-label text-right">メールアドレス</label>
        <div class="col-sm-10">
          <input type="text" id="txt_email" class="form-control" name="email" v-model="inputs.email">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_postal_code" class="col-sm-2 col-form-label text-right">郵便番号</label>
        <div class="col-sm-10">
          <input type="text" id="txt_postal_code" class="form-control" name="postal_code" v-model="inputs.postalCode">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_prefecture" class="col-sm-2 col-form-label text-right">都道府県</label>
        <div class="col-sm-10">
          <input type="text" id="txt_prefecture" class="form-control" name="prefecture" v-model="inputs.prefecture">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_city" class="col-sm-2 col-form-label text-right">市町村区</label>
        <div class="col-sm-10">
          <input type="text" id="txt_city" class="form-control" name="city" v-model="inputs.city">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_address" class="col-sm-2 col-form-label text-right">住所</label>
        <div class="col-sm-10">
          <input type="text" id="txt_address" class="form-control" name="address" v-model="inputs.address">
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_applicant_name" class="col-sm-2 col-form-label text-right">申込者名</label>
        <div class="col-sm-10">
          <input type="text" id="txt_applicant_name" class="form-control" name="applicant_name" v-model="inputs.applicantName">
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="application_source_id">申込経路</label>
        <div class="col-sm-10">
          <select name="application_source_id" id="application_source_id" class="form-control" v-model="inputs.applicationSourceId">
            <option disabled value="">選択してください</option>
            <option v-for="applicationSource in applicationSources" :key="applicationSource.id" :value="applicationSource.id">
              {{ applicationSource.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="txt_url" class="col-sm-2 col-form-label text-right">URL</label>
        <div class="col-sm-10">
          <input type="text" id="txt_url" class="form-control" name="url" v-model="inputs.url">
        </div>
      </div>
      <div class="form-group row">
        <label for="txt_note" class="col-sm-2 col-form-label text-right">
          備考<br><small>※取扱保険会社の確認方法、その他特記事項やメモがあれば入力</small>
        </label>
        <div class="col-sm-10">
          <textarea id="txt_note" class="form-control" name="note" rows="5" v-model="inputs.note"></textarea>
        </div>
      </div>
      <InsuranceCompanyConfirmationUrlInputField
        :old-inputs="oldInsuranceCompanyConfirmationUrls"
      >
      </InsuranceCompanyConfirmationUrlInputField>
      <InsuranceCompanyConfirmationDocumentUploadForm
        :max-number-of-registrations="maxNumberOfRegistrations"
        :old-inputs="oldDocuments"
      >
      </InsuranceCompanyConfirmationDocumentUploadForm>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right">取扱保険会社</label>
        <div class="col-sm-10">
          <div class="tabs">
            <div class="tab" :class="{ active: isLifeInsuranceTabActive() }" @click="switchSelectTab('lifeInsurance')">
              生命保険会社
            </div>
            <div class="tab" :class="{ active: isNonLifeInsuranceTabActive() }" @click="switchSelectTab('nonLifeInsurance')">
              損害保険会社
            </div>
          </div>
          <div class="company-select-form">
            <div v-show="isLifeInsuranceTabActive()" class="content">
              <div
                v-for="lifeInsuranceCompany in lifeInsuranceCompanies"
                :key="lifeInsuranceCompany.id"
                class="checkbox-wrapper"
              >
                <input
                  type="checkbox"
                  name="insurance_companies[]"
                  :value="lifeInsuranceCompany.id"
                  v-model="inputs.selectedLifeInsuranceCompanyIds"
                  :id="'life_insurance_company_' + lifeInsuranceCompany.id"
                  class="checkbox"
                >
                <label class="label" :for="'life_insurance_company_' + lifeInsuranceCompany.id">
                  {{ lifeInsuranceCompany.name }}
                </label>
              </div>
            </div>
            <div v-show="isNonLifeInsuranceTabActive()" class="content">
              <div
                v-for="nonLifeInsuranceCompany in nonLifeInsuranceCompanies"
                :key="nonLifeInsuranceCompany.id"
                class="checkbox-wrapper"
              >
                <input
                  type="checkbox"
                  name="insurance_companies[]"
                  :value="nonLifeInsuranceCompany.id"
                  v-model="inputs.selectedNonLifeInsuranceCompanyIds"
                  :id="'non_life_insurance_company_' + nonLifeInsuranceCompany.id"
                  class="checkbox"
                >
                <label class="label" :for="'non_life_insurance_company_' + nonLifeInsuranceCompany.id">
                  {{ nonLifeInsuranceCompany.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-info float-right mb-4" @click="switchConfirmationModal(true)">
        確認
      </button>
    </div>

    <div class="confirmation-modal-background" v-if="showConfirmationModal">
      <div class="confirmation-modal">
        <span class="close" @click="switchConfirmationModal(false)">×</span>
        <div class="confirmation-modal-header">
          <p>入力内容をご確認ください</p>
        </div>
        <div class="confirmation-modal-body">
          <div class="check-item">
            <input
              type="checkbox"
              class="checkbox"
              id="company_id_check"
              v-model="nameCheck"
            >
            <label class="label" for="company_id_check">企業名：</label>
            <p class="value">{{ inputs.name }}</p>
          </div>
          <div class="check-item">
            <input
              type="checkbox"
              class="checkbox"
              id="email_check"
              v-model="emailCheck"
            >
            <label class="label" for="email_check">メールアドレス：</label>
            <p class="value">{{ inputs.email }}</p>
          </div>
          <div class="check-item -column">
            <div class="label">取扱生命保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="selectedLifeInsuranceCompany in selectedLifeInsuranceCompaniesCheckList"
                :key="selectedLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="'selected_insurance_company_' + selectedLifeInsuranceCompany.id"
                  @change="switchSelectedInsuranceCompanyStatus(selectedLifeInsuranceCompany.id, selectedLifeInsuranceCompaniesCheck)"
                >
                <label class="value" :for="'selected_insurance_company_' + selectedLifeInsuranceCompany.id">
                  {{ selectedLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!selectedLifeInsuranceCompaniesCheckList.length">
                取扱なし
              </p>
            </div>
          </div>
          <div class="check-item -column -last">
            <div class="label">取扱損害保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="selectedNonLifeInsuranceCompany in selectedNonLifeInsuranceCompaniesCheckList"
                :key="selectedNonLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="
                    'selected_insurance_company_' +
                    selectedNonLifeInsuranceCompany.id
                  "
                  @change="
                    switchSelectedInsuranceCompanyStatus(
                      selectedNonLifeInsuranceCompany.id,
                      selectedNonLifeInsuranceCompaniesCheck
                    )
                  "
                >
                <label class="value" :for="'selected_insurance_company_' +selectedNonLifeInsuranceCompany.id">
                  {{ selectedNonLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!selectedNonLifeInsuranceCompaniesCheckList.length">
                取扱なし
              </p>
            </div>
          </div>
          <div class="buttons">
            <button
              type="button"
              class="btn btn-secondary"
              @click="switchConfirmationModal(false)"
            >
              キャンセル
            </button>
            <button
              type="submit"
              class="btn btn-info"
              :class="{ disabled: !isAllChecked }"
              :disabled="!isAllChecked"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              登録
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceCompanyConfirmationDocumentUploadForm from "../insurance_company_confirmation_document/UploadForm.vue"
import InsuranceCompanyConfirmationUrlInputField from "../insurance_company_confirmation_url/InputField.vue"

export default {
  components: {
    InsuranceCompanyConfirmationDocumentUploadForm,
    InsuranceCompanyConfirmationUrlInputField,
  },

  props: {
    applicationSources: {
      type: [Object, Array],
      required: true
    },
    lifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    nonLifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    companySalesModels: {
      type: [Object, Array],
      required: true
    },
    companyTypes: {
      type: [Object, Array],
      required: true
    },
    maxNumberOfRegistrations: {
      type: Number,
      required: true
    },
    oldName: {
      type: String,
      required: false
    },
    oldCompanyTypeId: {
      type: Number,
      required: false
    },
    oldSalesModelId: {
      type: Number,
      required: false
    },
    oldEmployeeNumber: {
      type: Number,
      required: false
    },
    oldEmail: {
      type: String,
      required: false
    },
    oldPostalCode: {
      type: String,
      required: false
    },
    oldPrefecture: {
      type: String,
      required: false
    },
    oldCity: {
      type: String,
      required: false
    },
    oldAddress: {
      type: String,
      required: false
    },
    oldApplicantName: {
      type: String,
      required: false
    },
    oldApplicationSourceId: {
      type: Number,
      required: false
    },
    oldUrl: {
      type: String,
      required: false
    },
    oldNote: {
      type: String,
      required: false
    },
    oldDocuments: {
      type: [Object, Array],
      required: false
    },
    oldInsuranceCompanyConfirmationUrls: {
      type: Object,
      required: false
    },
    oldSelectedInsuranceCompanyIds: {
      type: Array,
      required: false
    },
  },

  data() {
    return {
      inputs: {
        name: '',
        companyTypeId: '',
        salesModelId: '',
        employeeNumber: null,
        email: '',
        postalCode: '',
        prefecture: '',
        city: '',
        address: '',
        applicantName: '',
        applicationSourceId: '',
        url: '',
        note: '',
        selectedLifeInsuranceCompanyIds: [],
        selectedNonLifeInsuranceCompanyIds: [],
      },
      showConfirmationModal: false,
      nameCheck: false,
      emailCheck: false,
      selectedLifeInsuranceCompaniesCheck: [],
      selectedNonLifeInsuranceCompaniesCheck: [],
      isAllChecked: false,
      selectedLifeInsuranceCompaniesCheckList: [],
      selectedNonLifeInsuranceCompaniesCheckList: [],
      selectTab: 'lifeInsurance',
    }
  },

  mounted() {
    this.retainInputOnValidationError()
  },

  watch: {
    nameCheck() {
      this.isEverythingChecked()
    },
    emailCheck() {
      this.isEverythingChecked()
    },
    selectedLifeInsuranceCompaniesCheck: {
      handler() {
        this.hasCheckedSelectedLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
    selectedNonLifeInsuranceCompaniesCheck: {
      handler() {
        this.hasCheckedSelectedNonLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
  },

  methods: {
    switchConfirmationModal(isModalOpen) {
      this.showConfirmationModal = isModalOpen

      if (isModalOpen) {
        this.selectedLifeInsuranceCompaniesCheckList = this.createSelectedInsuranceCompaniesCheckList(
          this.inputs.selectedLifeInsuranceCompanyIds,
          this.lifeInsuranceCompanies
        )
        this.selectedNonLifeInsuranceCompaniesCheckList = this.createSelectedInsuranceCompaniesCheckList(
          this.inputs.selectedNonLifeInsuranceCompanyIds,
          this.nonLifeInsuranceCompanies
        )
      } else {
        this.selectedLifeInsuranceCompaniesCheck = []
        this.selectedNonLifeInsuranceCompaniesCheck = []
      }
    },

    // 引数のinputInsuranceCompanyIdsとinsuranceCompaniesから選択した保険会社のチェックリストを作成する
    createSelectedInsuranceCompaniesCheckList(inputInsuranceCompanyIds, insuranceCompanies) {
      const selectedInsuranceCompanyIds = inputInsuranceCompanyIds.map((id) => Number(id))
      return insuranceCompanies.filter(function (insuranceCompany) {
        return selectedInsuranceCompanyIds.includes(insuranceCompany.id)
      })
    },

    // 取扱生命/損害保険会社の確認にチェックが入った時、チェックが外れた時の処理
    switchSelectedInsuranceCompanyStatus(id, selectedInsuranceCompaniesCheck) {
      if (selectedInsuranceCompaniesCheck.includes(id)) {
        const index = selectedInsuranceCompaniesCheck.indexOf(id)
        selectedInsuranceCompaniesCheck.splice(index, 1)
      } else {
        selectedInsuranceCompaniesCheck.push(id)
      }
    },

    // 全てにチェックが入っているか
    isEverythingChecked() {
      if (
        this.nameCheck
        && this.emailCheck
        && this.hasCheckedSelectedLifeInsuranceCompanies()
        && this.hasCheckedSelectedNonLifeInsuranceCompanies()
      ) {
        this.isAllChecked = true
      } else {
        this.isAllChecked = false
      }
    },

    // 選択した生命保険会社全てにチェックが入っているか
    hasCheckedSelectedLifeInsuranceCompanies() {
      return this.inputs.selectedLifeInsuranceCompanyIds.length === this.selectedLifeInsuranceCompaniesCheck.length
    },

    // 選択した損害保険会社全てにチェックが入っているか
    hasCheckedSelectedNonLifeInsuranceCompanies() {
      return this.inputs.selectedNonLifeInsuranceCompanyIds.length === this.selectedNonLifeInsuranceCompaniesCheck.length
    },

    // タブを切り替える
    switchSelectTab(insuranceType) {
      this.selectTab = insuranceType
    },

    // 「生命保険会社」タブが開かれているか
    isLifeInsuranceTabActive() {
      return this.selectTab === 'lifeInsurance'
    },

    // 「損害保険会社」タブが開かれているか
    isNonLifeInsuranceTabActive() {
      return this.selectTab === 'nonLifeInsurance'
    },

    // バリデーションエラー時の入力値保持
    retainInputOnValidationError() {
      const inputs = this.inputs
      const self = this
      Object.keys(inputs).forEach(function (item) {
        let pascalCaseItemName = item.charAt(0).toUpperCase() + item.slice(1)
        if (self[`old${pascalCaseItemName}`]) {
          inputs[item] = self[`old${pascalCaseItemName}`]
        }
      })
      if (this.oldSelectedInsuranceCompanyIds) {
        this.oldSelectedInsuranceCompanyIds.forEach(function (oldSelectedInsuranceCompanyId) {
          self.lifeInsuranceCompanies.forEach(function (lifeInsuranceCompany) {
            if (lifeInsuranceCompany.id === Number(oldSelectedInsuranceCompanyId)) {
              inputs.selectedLifeInsuranceCompanyIds.push(oldSelectedInsuranceCompanyId)
            }
          })
          self.nonLifeInsuranceCompanies.forEach(function (nonLifeInsuranceCompany) {
            if (nonLifeInsuranceCompany.id === Number(oldSelectedInsuranceCompanyId)) {
              inputs.selectedNonLifeInsuranceCompanyIds.push(oldSelectedInsuranceCompanyId)
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  > .confirmation-modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    padding: 60px;
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    position: relative;
    overflow-y: scroll;
    max-height: 80vh;

    > .confirmation-modal-header {
      font-size: 28px;
      font-weight: 600;
      margin: 0 auto;
      padding-bottom: 15px;
      display: flex;
      justify-content: center;
    }

    > .confirmation-modal-body {
      margin-top: 20px;

      > .check-item {
        display: flex;
        padding: 20px;
        border-top: 2px solid #D9D9D9;

        &.-last {
          border-bottom: 2px solid #D9D9D9;
        }

        &.-column {
          flex-flow: column;

          > .checkbox-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            > .selected-insurance-company-group {
              padding: 7px 0;
              display: flex;
              align-items: flex-start;
              flex-basis: 50%;

              > .checkbox {
                height: 30px;
                width: 30px;
                cursor: pointer;
              }

              > .value {
                font-size: 17px;
                font-weight: 600;
                margin: 0;
                line-height: 32px;
                padding: 0 20px;
              }
            }

            > .no-selected {
              font-size: 17px;
              font-weight: 600;
              margin: 0;
              line-height: 32px;
              padding-left: 55px;
            }
          }
        }

        > .checkbox {
          height: 30px;
          width: 30px;
          cursor: pointer;
        }

        > .label {
          font-size: 18px;
          margin: 0;
          padding: 0 20px;
          line-height: 32px;
        }

        > .value {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
          line-height: 32px;
        }
      }

      > .buttons {
        padding-top: 30px;
        display: flex;
        justify-content: space-around;

        > .btn {
          font-size: 18px;
        }
      }
    }

    > .close {
      position: absolute;
      top: 1px;
      right: 8px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.disabled {
  background-color: #AAAAAA;
  border: 1px solid #AAAAAA;
  color: #7B7B7B;
}

.tabs {
  display: flex;
  justify-content: center;

  .tab {
    padding: 10px 20px;
    border: none;
    background: lightgray;
    cursor: pointer;
    width: 50%;
    border-radius: 10px 10px 0 0;
    text-align: center;
    color: #9A9A9A;

    &.active {
      background-color: white;
      color: #212529;
      font-weight: bold;
      border: 1px solid #ced4da;
      border-bottom: none;
    }
  }
}

.company-select-form {
  border: 1px solid #ced4da;
  border-top: none;
  padding: 13px 20px;

  > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > .checkbox-wrapper {
      flex-basis: 50%;
      padding: 10px 0;
      display: flex;
      align-items: flex-start;

      > .checkbox {
        cursor: pointer;
        height: 17px;
        width: 17px;
      }

      > .label {
        margin-bottom: 0;
        margin-left: 5px;
        line-height: 17px;
      }
    }
  }
}
</style>
