<template>
  <div class="mb-4">
    <div class="form-group row" v-for="number in numberOfFormSets" :key="number">
      <label for="txt_insurance_company_confirmation_method" class="col-sm-2 col-form-label text-right">
        取扱保険会社確認URL{{ getIndexNumber(number) }}
      </label>
      <div class="col-sm-10 pt-1">
        <input type="text" id="txt_confirmation_document_name" class="form-control mb-1" :name="getNameAttribute('name', number)"
          v-model="insuranceCompanyConfirmationUrlNames[number]" placeholder="URLのWebページ名を入力（ex:取扱生命保険会社ページ）"
        >
        <input type="text" id="txt_insurance_company_confirmation_method" class="form-control"
          :name="getNameAttribute('url', number)" v-model="insuranceCompanyConfirmationUrls[number]"
          placeholder="URLを入力"
        >
      </div>
    </div>
    <div class="col-sm-10 ml-auto d-flex justify-content-start">
      <button type="button" class="btn btn-outline-success" @click="addFormSet()">取扱保険会社確認URLを追加</button>
      <button v-if="getLastNumberOfFormSets() > 1 && numberOfFormSets" type="button" class="btn btn-outline-danger ml-4" @click="removeFormSet()">
        取扱保険会社確認URL{{ getLastNumberOfFormSets() }}を削除
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfRegisteredConfirmationUrls: {
      type: Number,
      required: false
    },
    oldInputs: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      numberOfFormSets: this.numberOfRegisteredConfirmationUrls ? 0 : 1,
      insuranceCompanyConfirmationUrls: [],
      insuranceCompanyConfirmationUrlNames: []
    }
  },

  mounted() {
    // エラーの際に入力値を保持する
    if (this.oldInputs) {
      this.numberOfFormSets = Object.keys(this.oldInputs).length
      let self = this
      let oldInsuranceCompanyConfirmationUrlNames = []
      let oldInsuranceCompanyConfirmationUrls = []
      this.insuranceCompanyConfirmationUrls = this.oldInputs
      Object.keys(this.oldInputs).forEach(function (key) {
        oldInsuranceCompanyConfirmationUrls[key] = self.oldInputs[key].url
        oldInsuranceCompanyConfirmationUrlNames[key] = self.oldInputs[key].name
      })
      this.insuranceCompanyConfirmationUrls = oldInsuranceCompanyConfirmationUrls
      this.insuranceCompanyConfirmationUrlNames = oldInsuranceCompanyConfirmationUrlNames
    }
  },

  methods: {
    // フォームを1セット追加する
    addFormSet() {
      this.numberOfFormSets ++
    },

    // フォームを1セット削除する
    removeFormSet() {
      this.numberOfFormSets --
    },

    // 与えられたtargetとindexからname属性を作成し返す
    getNameAttribute(target, index) {
      if (this.numberOfRegisteredConfirmationUrls) {
        return `insurance_company_confirmation_urls[${this.getIndexNumber(index)}][${target}]`
      }

      return `insurance_company_confirmation_urls[${index}][${target}]`
    },

    // 取扱保険会社確認URLが登録されている場合、与えられたindexに登録済みURLの数を足した数を返す
    getIndexNumber(index) {
      if (this.numberOfRegisteredConfirmationUrls) {
        return index + this.numberOfRegisteredConfirmationUrls
      }

      return index
    },

    // 取扱保険会社確認URLが登録されている場合、表示されているフォームセットの数に登録済みの確認URLの数を足した数を返す
    getLastNumberOfFormSets() {
      if (this.numberOfRegisteredConfirmationUrls) {
        return this.numberOfFormSets + this.numberOfRegisteredConfirmationUrls
      }

      return this.numberOfFormSets
    }
  }
}
</script>
