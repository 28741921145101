<template>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label text-left">
      {{ label }}<br>
      <small>{{ smallLabel }}</small>
    </label>
    <div class="col-sm-10">
      <div class="company-check-form">
        <div>
          <select v-model="insuranceCompanyId">
            <option :value="null" selected>保険会社を選択してください</option>
            <option v-for="insuranceCompany in insuranceCompanies" :value="insuranceCompany.id" :key="insuranceCompany.id">
              {{ insuranceCompany.name }}
            </option>
          </select>
          を取り扱っている企業を全て
          <button type="button" class="btn btn-outline-success ml-1" @click="checkCompaniesByInsuranceCompanyId()">
            チェック
          </button>
          <button type="button" class="btn btn-outline-warning ml-2" @click="clear()">チェックをクリア</button>
        </div>
        <div class="content">
          <div
            v-for="company in companies"
            :key="company.id"
            class="checkbox-wrapper"
          >
            <input
              type="checkbox"
              name="permitted_company_ids[]"
              :value="company.id"
              v-model="permittedCompanyIds"
              :id="'company_' + company.id"
              class="checkbox"
            >
            <label class="label" :for="'company_' + company.id">
              {{ company.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    companies: {
      type: [Object, Array],
      required: true
    },
    insuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    getCompanyIdsEndpoint: {
      type: String,
      required: true
    },
    registeredPermissionCompanyIds: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    smallLabel: {
      type: String,
      required: false
    },
  },

  data() {
    return {
      permittedCompanyIds: [],
      insuranceCompanyId: null
    }
  },

  mounted() {
    // registeredPermissionCompanyIdsがundefinedの場合は何もしない
    if (this.registeredPermissionCompanyIds === undefined) {
      return
    }
    // セミナー編集ページで表示対象の企業が登録されている場合は選択をチェックボックスに反映させる
    if (this.registeredPermissionCompanyIds.length) {
      this.permittedCompanyIds = this.registeredPermissionCompanyIds
    }
  },

  methods: {
    // 選択した保険会社を取り扱っている企業を全てチェックする
    checkCompaniesByInsuranceCompanyId() {
      if (!this.insuranceCompanyId) {
        alert('保険会社を選択してください。')
        return
      }

      axios.get(this.getCompanyIdsEndpoint, {
        params: {
          insurance_company_id: this.insuranceCompanyId
        }
      })
      .then((response) => {
        this.permittedCompanyIds = response.data
      })
      .catch((error) => {
        alert(error.response.data.message)
      })
    },

    // チェックボックスの選択をクリアする
    clear() {
      this.permittedCompanyIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
.company-check-form {
  border: 1px solid #ced4da;
  padding: 13px 20px;

  > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > .checkbox-wrapper {
      flex-basis: 25%;
      padding: 10px 0;
      display: flex;
      align-items: flex-start;

      > .checkbox {
        cursor: pointer;
        height: 17px;
        width: 17px;
      }

      > .label {
        margin-bottom: 0;
        margin-left: 5px;
        line-height: 17px;
      }
    }
  }
}
</style>
