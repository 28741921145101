// submitボタンのダブルクリック対策
document.addEventListener('DOMContentLoaded', function() {
  const submitElements = document.querySelectorAll('button[type="submit"]')
  submitElements.forEach(function(element) {
    element.addEventListener('click', function() {
      element.classList.add('-disabled')
      element.blur()
    });
  });
});
