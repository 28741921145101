<template>
  <div>
    <input
      type="text"
      class="input-form"
      v-model="sortOrder"
      @blur="handleBlur()"
      @focus="handleFocus()"
    >
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    updateSortOrderEndpoint: {
      type: String,
      required: true
    },
    registeredSortOrder: {
      type: Number,
      required: false
    },
  },

  data() {
    return {
      sortOrder: '',
      initialSortOrder: ''
    }
  },

  mounted() {
    this.addComma()
  },

  methods: {
    /**
     * 3桁区切りのカンマを追加する
     * @return {void}
     */
    addComma() {
      if (this.registeredSortOrder) {
        this.sortOrder = this.registeredSortOrder.toLocaleString()
      }
    },

    /**
     * フォーカスが当たったら3桁区切りのカンマを削除する
     * @return {void}
     */
    handleFocus() {
      this.sortOrder = this.registeredSortOrder
      this.initialSortOrder = this.sortOrder
    },

    /**
     * フォーカスが外れたらsort_orderを更新しリロードする
     * @return {void}
     */
    async handleBlur() {
      // sortOrderの値が変更されていなければ何もしない
      if (Number(this.sortOrder) === Number(this.initialSortOrder)) {
        return;
      }

      await this.requestApi()
      location.reload()
    },

    /**
     * 引受目安目安の並び順番号更新APIにリクエストを送信する
     * @returns {Promise<void>}
     */
    async requestApi() {
      await axios.patch(this.updateSortOrderEndpoint,{
        sort_order: this.sortOrder
      })
      .then((response) => {
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.messages.sort_order)
          alert(error.response.data.messages.sort_order[0])
        }
        if (error.response.status === 500) {
          alert(error.response.data.message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-form {
  width: 100px;

  &:focus {
    outline: 5px solid #4D90FE;
  }
}
</style>
