<template>
  <div class="mb-4">
    <div class="form-group row" v-for="number in numberOfFormSets" :key="number">
      <label for="txt_confirmation_document_name" class="col-sm-2 col-form-label text-right">
        取扱保険会社の確認に利用したファイル{{ getIndexNumber(number) }}
        <small v-if="getIndexNumber(number) === 1"><br>※URLがない場合は必須</small>
      </label>
      <div class="col-sm-10 pt-1">
        <input type="text" id="txt_confirmation_document_name" class="form-control" :name="getNameAttribute('name', number)"
          v-model="documentNames[number]" placeholder="ファイル名を入力"
        >
        <input type="file" accept="application/pdf" id="txt_confirmation_document" class="mt-2" :name="getNameAttribute('file', number)">
      </div>
    </div>
    <div class="col-sm-10 ml-auto d-flex justify-content-start">
      <button type="button" class="btn btn-outline-success" @click="addFormSet()">確認に利用したファイルを追加</button>
      <button v-if="showRemoveButton()" type="button" class="btn btn-outline-danger ml-4" @click="removeFormSet()">
        確認に利用したファイル{{ getLastNumberOfFormSets() }}を削除
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfRegisteredDocuments: {
      type: Number,
      required: false
    },
    maxNumberOfRegistrations: {
      type: Number,
      required: true
    },
    oldInputs: {
      type: [Object, Array],
      required: false
    },
    isEditPage: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      numberOfFormSets: this.isEditPage ? 0 : 1,
      documentNames: []
    }
  },

  methods: {
    // フォームを1セット追加する
    addFormSet() {
      if (
        this.numberOfFormSets === this.maxNumberOfRegistrations
        || this.numberOfFormSets + this.numberOfRegisteredDocuments === this.maxNumberOfRegistrations
      ) {
        alert(`取扱保険会社の確認に利用したファイルは最大${this.maxNumberOfRegistrations}件までしか登録できません`)
        return
      }
      this.numberOfFormSets ++
    },

    // フォームを1セット削除する
    removeFormSet() {
      this.numberOfFormSets --
    },

    // 与えられたtargetとindexからname属性を作成し返す
    getNameAttribute(target, index) {
      if (this.numberOfRegisteredDocuments) {
        return `documents[${this.getIndexNumber(index)}][${target}]`
      }

      return `documents[${index}][${target}]`
    },

    // 取扱保険会社の確認に利用したファイルが登録されている場合、
    // 与えられたindexに登録済みのファイル数を足した数を返す
    getIndexNumber(index) {
      if (this.numberOfRegisteredDocuments) {
        return index + this.numberOfRegisteredDocuments
      }

      return index
    },

    // 取扱保険会社の確認に利用したファイルが登録されている場合、
    // 表示されているフォームセットの数に登録済みのファイル数を足した数を返す
    getLastNumberOfFormSets() {
      if (this.numberOfRegisteredDocuments) {
        return this.numberOfFormSets + this.numberOfRegisteredDocuments
      }

      return this.numberOfFormSets
    },

    // フォームセット削除ボタンを表示するかどうかの真偽値を返す
    showRemoveButton() {
      if (this.isEditPage && this.numberOfFormSets > 0) {
        return true;
      }

      return this.numberOfFormSets > 1
    }
  },

  mounted() {
    // 登録でエラーになった際にファイル名を入力フォームに保持させる
    if (this.oldInputs) {
      let self = this
      let oldDocumentNames = []
      Object.keys(this.oldInputs).forEach(function (key) {
        oldDocumentNames[key] = self.oldInputs[key].name
      })
      this.numberOfFormSets = Object.keys(this.oldInputs).length
      this.documentNames = oldDocumentNames
    }
  }
}
</script>
