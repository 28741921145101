(function ($) {
  let inputCount = ($('.existing-addable-deletable-root > .existing-addable-deletable-element').length) ?
    $('.existing-addable-deletable-root > .existing-addable-deletable-element').length + 1:
    $('.addable-deletable-root > .addable-deletable-element').length

  $(document).on("click", ".add", function () {
    let original = $(this).parents('.addable-deletable-element')

    let cloned = original.clone(true).insertAfter(original)
    cloned.find("input[type='text']").each(function () {
      let newName = $(this).attr('name').replace(/(\[)\d+(\]\[)/, '$1' + inputCount + '$2')
      $(this).val("").attr('name', newName)
    })
    inputCount++
  })

  $(document).on("click", ".add-with-value", function () {
    let original = $(this).parents('.addable-deletable-element')

    let cloned = original.clone(true).insertAfter(original)

    let notList = '.add-with-value, .delete'
    cloned.find('input').not(notList).each(function () {
      let newName = $(this).attr('name').replace(/(\[)\d+(\]\[)/, '$1' + inputCount + '$2')
      let newValue = $(this).val()

      if ($(this).attr('type').toLowerCase() === 'file') {
        $(this).attr('name', newName)
      } else {
        $(this).val(newValue).attr('name', newName)
      }
    })

    cloned.find('select').not(notList).each(function () {
      const originalName = $(this).attr('name')
      const selectedValue = original.find('select[name="' + originalName + '"] option:selected').val()
      let newName = originalName.replace(/(\[)\d+(\]\[)/, '$1' + inputCount + '$2')
      $(this).val(selectedValue).attr('name', newName)
    })
    inputCount++

    let elementTitle = cloned.find(".addable-deletable-element-title").text()
    elementTitle = elementTitle.replace(/\d+/, inputCount)
    cloned.find(".addable-deletable-element-title").text(elementTitle)
  })

  $(document).on("click", ".delete", function () {
    let elementCount = $(this).parents('.addable-deletable-root').children().length

    if (elementCount > 1) {
      $(this).parents('.addable-deletable-element').remove()
    }
  })

  $(document).on("click", ".delete-existing", function () {
    $(this).parents('.existing-addable-deletable-element').remove()
  })
}) (window.jQuery)
