<template>
  <div class="form-group row" :class="{ required: isRequired }">
    <label :for="nameAttribute" class="col-sm-2 col-form-label text-right">{{ labelName }}</label>
    <v-select
      :id="nameAttribute"
      :options="options"
      class="common"
      :class="selectBoxClass"
      placeholder="選択してください"
      v-model="selectedOption"
    >
    </v-select>
    <input type="hidden" :name="nameAttribute" :value="selectedOption.code">
  </div>
</template>

<script>
export default {
  props: {
    labelName: {
      type: String,
      required: true
    },
    nameAttribute: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      required: true
    },
    optionData: {
      type: [Object, Array],
      required: true
    },
    oldCode: {
      type: Number,
      required: false
    },
    selectedValue: {
      type: Number,
      required: false
    },
    selectBoxClass: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selectedOption: '',
      options: [],
    }
  },

  created() {
    this.generateOptions()
  },

  mounted() {
    this.setDefaultOption()

    // エラー時の選択値保持
    if (this.oldCode) {
      const oldCode = this.oldCode
      this.selectedOption = this.options.filter(function(option) {
        return option.code == oldCode
      })[0]
    }
  },

  methods: {
    // セレクトボックスの選択肢を生成する
    generateOptions() {
      for (const data of this.optionData) {
        this.options.push({code: data.id, label: data.name})
      }
    },

    // デフォルトのオプションを選択した状態にする
    setDefaultOption() {
      if (this.selectedValue) {
        const selectedValue = this.selectedValue
        this.selectedOption = this.options.filter(function(option) {
          return option.code === selectedValue
        })[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common {
  padding: 0 15px;
  cursor: pointer;

  > .vs__dropdown-toggle {
    > .vs__actions {
      > .vs__clear {
        display: none;
      }
    }
  }
}
</style>
