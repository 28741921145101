<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label text-right">取扱保険会社</label>
      <div class="col-sm-10">
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: isLifeInsuranceTabActive() }"
            @click="switchSelectTab('lifeInsurance')"
          >
            生命保険会社
          </div>
          <div
            class="tab"
            :class="{ active: isNonLifeInsuranceTabActive() }"
            @click="switchSelectTab('nonLifeInsurance')"
          >
            損害保険会社
          </div>
        </div>
        <div class="insurance-companies">
          <div v-show="isLifeInsuranceTabActive()" class="content">
            <div
              v-for="lifeInsuranceCompany in lifeInsuranceCompanies"
              :key="lifeInsuranceCompany.id"
              class="insurance-company-wrapper"
            >
              <div class="name">{{ lifeInsuranceCompany.name }}</div>
            </div>
            <p v-if="!lifeInsuranceCompanies.length">取扱なし</p>
          </div>
          <div v-show="isNonLifeInsuranceTabActive()" class="content">
            <div
              v-for="nonLifeInsuranceCompany in nonLifeInsuranceCompanies"
              :key="nonLifeInsuranceCompany.id"
              class="insurance-company-wrapper"
            >
              <div class="name">{{ nonLifeInsuranceCompany.name }}</div>
            </div>
            <p v-if="!nonLifeInsuranceCompanies.length">取扱なし</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    nonLifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
  },

  data() {
    return {
      selectTab: 'lifeInsurance',
    }
  },

  methods: {
    // タブを切り替える
    switchSelectTab(insuranceType) {
      this.selectTab = insuranceType
    },

    // 「生命保険会社」タブが開かれているか
    isLifeInsuranceTabActive() {
      return this.selectTab === 'lifeInsurance'
    },

    // 「損害保険会社」タブが開かれているか
    isNonLifeInsuranceTabActive() {
      return this.selectTab === 'nonLifeInsurance'
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  justify-content: center;

  .tab {
    padding: 10px 20px;
    border: none;
    background: lightgray;
    cursor: pointer;
    width: 50%;
    border-radius: 10px 10px 0 0;
    text-align: center;
    color: #9A9A9A;

    &.active {
      background-color: #E9ECEF;
      color: #212529;
      font-weight: bold;
      border: 1px solid #ced4da;
      border-bottom: none;
    }
  }
}

.insurance-companies {
  background-color: #E9ECEF;
  border: 1px solid #ced4da;
  border-top: none;
  padding: 13px 20px;

  > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > .insurance-company-wrapper {
      flex-basis: 50%;
      padding: 10px 0;
      display: flex;
      align-items: flex-start;

      > .name {
        margin-bottom: 0;
        margin-left: 5px;
        line-height: 17px;
      }
    }
  }
}
</style>
