<template>
  <div>
    <div class="form-container">
      <div class="form-group row required">
        <label for="txt_name" class="col-sm-2 col-form-label text-right">企業名</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_name"
            class="form-control"
            name="name"
            v-model="inputs.name"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="company_type_id">企業タイプ</label>
        <div class="col-sm-10">
          <select
            name="company_type_id"
            id="company_type_id"
            class="form-control"
            v-model="inputs.companyTypeId"
          >
            <option disabled value="">選択してください</option>
            <option
              v-for="companyType in companyTypes"
              :key="companyType.id"
              :value="companyType.id"
            >
              {{ companyType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="sales_model_id">種別</label>
        <div class="col-sm-10">
          <select
            name="sales_model_id"
            id="sales_model_id"
            class="form-control"
            v-model="inputs.salesModelId"
          >
            <option disabled value="">選択してください</option>
            <option
              v-for="companySalesModel in companySalesModels"
              :key="companySalesModel.id"
              :value="companySalesModel.id"
            >
              {{ companySalesModel.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_employee_number" class="col-sm-2 col-form-label text-right">従業員数</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_employee_number"
            class="form-control"
            name="employee_number"
            v-model="inputs.employeeNumber"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_email" class="col-sm-2 col-form-label text-right">メールアドレス</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_email"
            class="form-control"
            name="email"
            v-model="inputs.email"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_postal_code" class="col-sm-2 col-form-label text-right">郵便番号</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_postal_code"
            class="form-control"
            name="postal_code"
            v-model="inputs.postalCode"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_prefecture" class="col-sm-2 col-form-label text-right">都道府県</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_prefecture"
            class="form-control"
            name="prefecture"
            v-model="inputs.prefecture"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_city" class="col-sm-2 col-form-label text-right">市町村区</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_city"
            class="form-control"
            name="city"
            v-model="inputs.city"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_address" class="col-sm-2 col-form-label text-right">住所</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_address"
            class="form-control"
            name="address"
            v-model="inputs.address"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label for="txt_applicant_name" class="col-sm-2 col-form-label text-right">申込者名</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_applicant_name"
            class="form-control"
            name="applicant_name"
            v-model="inputs.applicantName"
          >
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-sm-2 col-form-label text-right" for="application_source_id">申込経路</label>
        <div class="col-sm-10">
          <select
            name="application_source_id"
            id="application_source_id"
            class="form-control"
            v-model="inputs.applicationSourceId"
          >
            <option disabled value="">選択してください</option>
            <option
              v-for="applicationSource in applicationSources"
              :key="applicationSource.id"
              :value="applicationSource.id"
            >
              {{ applicationSource.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="txt_url" class="col-sm-2 col-form-label text-right">URL</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="txt_url"
            class="form-control"
            name="url"
            v-model="inputs.url"
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="txt_note" class="col-sm-2 col-form-label text-right">
          備考<br><small>※取扱保険会社の確認方法、その他特記事項やメモがあれば入力</small>
        </label>
        <div class="col-sm-10">
          <textarea id="txt_note" class="form-control" name="note" rows="5" v-model="inputs.note"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right">リーズタブ表示状況</label>
        <div class="col-sm-10">
          <input type="radio" class="mt-3 radio" name="show_insurance_leads" value="1" id="show" v-model="inputs.showInsuranceLeads">
          <label for="show" class="radio-label">表示</label>
          <input type="radio" class="mt-3 ml-4 radio" name="show_insurance_leads" value="0" id="hide" v-model="inputs.showInsuranceLeads">
          <label for="hide" class="radio-label">非表示</label>
        </div>
      </div>
      <InsuranceCompanyConfirmationUrlDisplayAndDeleteField
        :insurance-company-confirmation-urls="registeredInsuranceCompanyConfirmationUrls"
        :insurance-company-confirmation-url-destroy-endpoint="insuranceCompanyConfirmationUrlDestroyEndpoint"
        :csrf-token="csrfToken"
      >
      </InsuranceCompanyConfirmationUrlDisplayAndDeleteField>
      <InsuranceCompanyConfirmationDocumentDisplayAndDeleteField
        :insurance-company-confirmation-documents="registeredDocuments"
        :insurance-company-confirmation-document-destroy-endpoint="insuranceCompanyConfirmationDocumentDestroyEndpoint"
        :csrf-token="csrfToken"
        :max-number-of-registrations="maxNumberOfRegistrations"
      >
      </InsuranceCompanyConfirmationDocumentDisplayAndDeleteField>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right">取扱保険会社</label>
        <div class="col-sm-10">
          <div class="tabs">
            <div
              class="tab"
              :class="{ active: isLifeInsuranceTabActive() }"
              @click="switchSelectTab('lifeInsurance')"
            >
              生命保険会社
            </div>
            <div
              class="tab"
              :class="{ active: isNonLifeInsuranceTabActive() }"
              @click="switchSelectTab('nonLifeInsurance')"
            >
              損害保険会社
            </div>
          </div>
          <div class="company-select-form">
            <div v-show="isLifeInsuranceTabActive()" class="content">
              <div
                v-for="lifeInsuranceCompany in lifeInsuranceCompanies"
                :key="lifeInsuranceCompany.id"
                class="checkbox-wrapper"
              >
                <input
                  type="checkbox"
                  name="insurance_companies[]"
                  :value="lifeInsuranceCompany.id"
                  v-model="inputs.selectedLifeInsuranceCompanyIds"
                  :id="'life_insurance_company_' + lifeInsuranceCompany.id"
                  class="checkbox"
                >
                <label class="label" :for="'life_insurance_company_' + lifeInsuranceCompany.id">
                  {{ lifeInsuranceCompany.name }}
                </label>
              </div>
            </div>
            <div v-show="isNonLifeInsuranceTabActive()" class="content">
              <div
                v-for="nonLifeInsuranceCompany in nonLifeInsuranceCompanies"
                :key="nonLifeInsuranceCompany.id"
                class="checkbox-wrapper"
              >
                <input
                  type="checkbox"
                  name="insurance_companies[]"
                  :value="nonLifeInsuranceCompany.id"
                  v-model="inputs.selectedNonLifeInsuranceCompanyIds"
                  :id="
                    'non_life_insurance_company_' + nonLifeInsuranceCompany.id
                  "
                  class="checkbox"
                >
                <label class="label" :for="'non_life_insurance_company_' + nonLifeInsuranceCompany.id">
                  {{ nonLifeInsuranceCompany.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-info float-right mb-4"
        @click="switchConfirmationModal(true)"
      >
        確認
      </button>
    </div>

    <div class="confirmation-modal-background" v-if="showConfirmationModal">
      <div class="confirmation-modal">
        <span class="close" @click="switchConfirmationModal(false)">×</span>
        <div class="confirmation-modal-header">
          <p>入力内容をご確認ください</p>
        </div>
        <div class="confirmation-modal-body">
          <div class="check-item">
            <input
              type="checkbox"
              class="checkbox"
              id="company_id_check"
              v-model="nameCheck"
            >
            <label class="label" for="company_id_check">企業名：</label>
            <p class="value">{{ inputs.name }}</p>
          </div>
          <div class="check-item">
            <input
              type="checkbox"
              class="checkbox"
              id="email_check"
              v-model="emailCheck"
            >
            <label class="label" for="email_check">メールアドレス：</label>
            <p class="value">{{ inputs.email }}</p>
          </div>
          <div class="check-item -column">
            <div class="label">追加する生命保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="addLifeInsuranceCompany in lifeInsuranceCompaniesToAddCheckList"
                :key="addLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="'add_life_insurance_company_' + addLifeInsuranceCompany.id"
                  @change="switchSelectedInsuranceCompanyStatus(addLifeInsuranceCompany.id, lifeInsuranceCompaniesToAddCheck)"
                >
                <label class="value" :for="'add_life_insurance_company_' + addLifeInsuranceCompany.id">
                  {{ addLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!lifeInsuranceCompaniesToAddCheckList.length">
                なし
              </p>
            </div>
          </div>
          <div class="check-item -column">
            <div class="label">削除する生命保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="deleteLifeInsuranceCompany in lifeInsuranceCompaniesToDeleteCheckList"
                :key="deleteLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="
                    'delete_life_insurance_company_' +
                    deleteLifeInsuranceCompany.id
                  "
                  @change="
                    switchSelectedInsuranceCompanyStatus(
                      deleteLifeInsuranceCompany.id,
                      lifeInsuranceCompaniesToDeleteCheck
                    )
                  "
                >
                <label class="value" :for="'delete_life_insurance_company_' + deleteLifeInsuranceCompany.id">
                  {{ deleteLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!lifeInsuranceCompaniesToDeleteCheckList.length">
                なし
              </p>
            </div>
          </div>
          <div class="check-item -column">
            <div class="label">追加する損害保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="addNonLifeInsuranceCompany in nonLifeInsuranceCompaniesToAddCheckList"
                :key="addNonLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="'add_non_life_insurance_company_' + addNonLifeInsuranceCompany.id"
                  @change="switchSelectedInsuranceCompanyStatus(addNonLifeInsuranceCompany.id, nonLifeInsuranceCompaniesToAddCheck)"
                >
                <label class="value" :for="'add_non_life_insurance_company_' + addNonLifeInsuranceCompany.id">
                  {{ addNonLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!nonLifeInsuranceCompaniesToAddCheckList.length">
                なし
              </p>
            </div>
          </div>
          <div class="check-item -column -last">
            <div class="label">削除する損害保険会社</div>
            <div class="checkbox-wrapper">
              <div
                class="selected-insurance-company-group"
                v-for="deleteNonLifeInsuranceCompany in nonLifeInsuranceCompaniesToDeleteCheckList"
                :key="deleteNonLifeInsuranceCompany.id"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  :id="'delete_non_life_insurance_company_' + deleteNonLifeInsuranceCompany.id"
                  @change="switchSelectedInsuranceCompanyStatus(deleteNonLifeInsuranceCompany.id, nonLifeInsuranceCompaniesToDeleteCheck)"
                >
                <label
                  class="value"
                  :for="'delete_non_life_insurance_company_' + deleteNonLifeInsuranceCompany.id"
                >
                  {{ deleteNonLifeInsuranceCompany.name }}
                </label>
              </div>
              <p class="no-selected" v-if="!nonLifeInsuranceCompaniesToDeleteCheckList.length">
                なし
              </p>
            </div>
          </div>
          <div class="buttons">
            <button
              type="button"
              class="btn btn-secondary"
              @click="switchConfirmationModal(false)"
            >
              キャンセル
            </button>
            <button
              type="submit"
              class="btn btn-info"
              :class="{ disabled: !isAllChecked }"
              :disabled="!isAllChecked"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-save"
              >
                <path
                  d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
                ></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
              更新
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceCompanyConfirmationDocumentDisplayAndDeleteField from "../insurance_company_confirmation_document/DisplayAndDeleteField.vue"
import InsuranceCompanyConfirmationUrlDisplayAndDeleteField from "../insurance_company_confirmation_url/DisplayAndDeleteField.vue"

export default {
  components: {
    InsuranceCompanyConfirmationDocumentDisplayAndDeleteField,
    InsuranceCompanyConfirmationUrlDisplayAndDeleteField,
  },

  props: {
    applicationSources: {
      type: [Object, Array],
      required: true
    },
    lifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    nonLifeInsuranceCompanies: {
      type: [Object, Array],
      required: true
    },
    companySalesModels: {
      type: [Object, Array],
      required: true
    },
    companyTypes: {
      type: [Object, Array],
      required: true
    },
    maxNumberOfRegistrations: {
      type: Number,
      required: true
    },
    registeredName: {
      type: String,
      required: true
    },
    registeredCompanyTypeId: {
      type: Number,
      required: true
    },
    registeredSalesModelId: {
      type: Number,
      required: true
    },
    registeredEmployeeNumber: {
      type: String,
      required: true
    },
    registeredEmail: {
      type: String,
      required: false
    },
    registeredPostalCode: {
      type: String,
      required: true
    },
    registeredPrefecture: {
      type: String,
      required: true
    },
    registeredCity: {
      type: String,
      required: true
    },
    registeredAddress: {
      type: String,
      required: true
    },
    registeredApplicantName: {
      type: String,
      required: true
    },
    registeredApplicationSourceId: {
      type: Number,
      required: true
    },
    registeredUrl: {
      type: String,
      required: true
    },
    registeredNote: {
      type: String,
      required: false
    },
    registeredShowInsuranceLeads: {
      type: String,
      required: true
    },
    registeredDocuments: {
      type: [Object, Array],
      required: false
    },
    registeredInsuranceCompanyConfirmationUrls: {
      type: [Object, Array],
      required: false
    },
    registeredSelectedInsuranceCompanyIds: {
      type: Array,
      required: true
    },
    insuranceCompanyConfirmationDocumentDestroyEndpoint: {
      type: String,
      required: true
    },
    insuranceCompanyConfirmationUrlDestroyEndpoint: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      inputs: {
        name: '',
        companyTypeId: '',
        salesModelId: '',
        employeeNumber: null,
        email: '',
        postalCode: '',
        prefecture: '',
        city: '',
        address: '',
        applicantName: '',
        applicationSourceId: '',
        url: '',
        note: '',
        showInsuranceLeads: '',
        insuranceCompanyConfirmationMethod: '',
        selectedLifeInsuranceCompanyIds: [],
        selectedNonLifeInsuranceCompanyIds: [],
      },
      showConfirmationModal: false,
      nameCheck: false,
      emailCheck: false,
      lifeInsuranceCompaniesToAddCheck: [],
      lifeInsuranceCompaniesToDeleteCheck: [],
      nonLifeInsuranceCompaniesToAddCheck: [],
      nonLifeInsuranceCompaniesToDeleteCheck: [],
      isAllChecked: false,
      lifeInsuranceCompaniesToAddCheckList: [],
      lifeInsuranceCompaniesToDeleteCheckList: [],
      nonLifeInsuranceCompaniesToAddCheckList: [],
      nonLifeInsuranceCompaniesToDeleteCheckList: [],
      selectTab: 'lifeInsurance',
    }
  },

  mounted() {
    this.setRegisteredValues()
  },

  watch: {
    nameCheck() {
      this.isEverythingChecked()
    },
    emailCheck() {
      this.isEverythingChecked()
    },
    lifeInsuranceCompaniesToAddCheck: {
      handler() {
        this.hasCheckedAddLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
    lifeInsuranceCompaniesToDeleteCheck: {
      handler() {
        this.hasCheckedDeleteLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
    nonLifeInsuranceCompaniesToAddCheck: {
      handler() {
        this.hasCheckedAddNonLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
    nonLifeInsuranceCompaniesToDeleteCheck: {
      handler() {
        this.hasCheckedDeleteNonLifeInsuranceCompanies()
        this.isEverythingChecked()
      },
      deep: true
    },
  },

  methods: {
    switchConfirmationModal(isModalOpen) {
      this.showConfirmationModal = isModalOpen

      if (isModalOpen) {
        this.lifeInsuranceCompaniesToAddCheckList = this.createInsuranceCompaniesToAddCheckList(
          this.inputs.selectedLifeInsuranceCompanyIds,
          this.lifeInsuranceCompanies
        )
        this.lifeInsuranceCompaniesToDeleteCheckList = this.createInsuranceCompaniesToDeleteCheckList(
          this.inputs.selectedLifeInsuranceCompanyIds,
          this.lifeInsuranceCompanies
        )
        this.nonLifeInsuranceCompaniesToAddCheckList = this.createInsuranceCompaniesToAddCheckList(
          this.inputs.selectedNonLifeInsuranceCompanyIds,
          this.nonLifeInsuranceCompanies
        )
        this.nonLifeInsuranceCompaniesToDeleteCheckList = this.createInsuranceCompaniesToDeleteCheckList(
          this.inputs.selectedNonLifeInsuranceCompanyIds,
          this.nonLifeInsuranceCompanies
        )
      } else {
        this.lifeInsuranceCompaniesToAddCheck = []
        this.lifeInsuranceCompaniesToDeleteCheck = []
        this.nonLifeInsuranceCompaniesToAddCheck = []
        this.nonLifeInsuranceCompaniesToDeleteCheck = []
      }
    },

    // 引数のselectedInsuranceCompanyIdsとinsuranceCompaniesから追加した生命/損害保険会社のチェックリストを作成する
    createInsuranceCompaniesToAddCheckList(selectedInsuranceCompanyIds, insuranceCompanies) {
      const addInsuranceCompanyIds = []
      for (let selectedInsuranceCompanyId of selectedInsuranceCompanyIds) {
        if (!this.registeredSelectedInsuranceCompanyIds.includes(Number(selectedInsuranceCompanyId))) {
          addInsuranceCompanyIds.push(Number(selectedInsuranceCompanyId))
        }
      }
      return insuranceCompanies.filter(function (insuranceCompany) {
        return addInsuranceCompanyIds.includes(insuranceCompany.id)
      })
    },

    // 引数のselectedInsuranceCompanyIdsとinsuranceCompaniesから削除した生命/損害保険会社のチェックリストを作成する
    createInsuranceCompaniesToDeleteCheckList(selectedInsuranceCompanyIds, insuranceCompanies) {
      const deleteInsuranceCompanyIds = []
      for (let registeredSelectedInsuranceCompanyId of this.registeredSelectedInsuranceCompanyIds) {
        if (!selectedInsuranceCompanyIds.includes(Number(registeredSelectedInsuranceCompanyId))) {
          deleteInsuranceCompanyIds.push(Number(registeredSelectedInsuranceCompanyId))
        }
      }
      return insuranceCompanies.filter(function (insuranceCompany) {
        return deleteInsuranceCompanyIds.includes(insuranceCompany.id)
      })
    },

    // 追加/削除する生命/損害保険会社の確認にチェックが入った時、チェックが外れた時の処理
    switchSelectedInsuranceCompanyStatus(id, insuranceCompaniesCheck) {
      if (insuranceCompaniesCheck.includes(id)) {
        const index = insuranceCompaniesCheck.indexOf(id)
        insuranceCompaniesCheck.splice(index, 1)
      } else {
        insuranceCompaniesCheck.push(id)
      }
    },

    // 全てにチェックが入っているか
    isEverythingChecked() {
      if (
        this.nameCheck
        && this.emailCheck
        && this.hasCheckedAddLifeInsuranceCompanies()
        && this.hasCheckedDeleteLifeInsuranceCompanies()
        && this.hasCheckedAddNonLifeInsuranceCompanies()
        && this.hasCheckedDeleteNonLifeInsuranceCompanies()
      ) {
        this.isAllChecked = true
      } else {
        this.isAllChecked = false
      }
    },

    // 追加した生命保険会社全てにチェックが入っているか
    hasCheckedAddLifeInsuranceCompanies() {
      return this.lifeInsuranceCompaniesToAddCheckList.length === this.lifeInsuranceCompaniesToAddCheck.length
    },

    // 削除した生命保険会社全てにチェックが入っているか
    hasCheckedDeleteLifeInsuranceCompanies() {
      return this.lifeInsuranceCompaniesToDeleteCheckList.length === this.lifeInsuranceCompaniesToDeleteCheck.length
    },

    // 追加した損害保険会社全てにチェックが入っているか
    hasCheckedAddNonLifeInsuranceCompanies() {
      return this.nonLifeInsuranceCompaniesToAddCheckList.length === this.nonLifeInsuranceCompaniesToAddCheck.length
    },

    // 削除した損害保険会社全てにチェックが入っているか
    hasCheckedDeleteNonLifeInsuranceCompanies() {
      return this.nonLifeInsuranceCompaniesToDeleteCheckList.length === this.nonLifeInsuranceCompaniesToDeleteCheck.length
    },

    // タブを切り替える
    switchSelectTab(insuranceType) {
      this.selectTab = insuranceType
    },

    // 「生命保険会社」タブが開かれているか
    isLifeInsuranceTabActive() {
      return this.selectTab === 'lifeInsurance'
    },

    // 「損害保険会社」タブが開かれているか
    isNonLifeInsuranceTabActive() {
      return this.selectTab === 'nonLifeInsurance'
    },

    // 登録済の値をフォームへ反映させる
    setRegisteredValues() {
      const inputs = this.inputs
      const self = this
      Object.keys(inputs).forEach(function (item) {
        let pascalCaseItemName = item.charAt(0).toUpperCase() + item.slice(1)
        if (self[`registered${pascalCaseItemName}`]) {
          inputs[item] = self[`registered${pascalCaseItemName}`]
        }
      })
      this.registeredSelectedInsuranceCompanyIds.forEach(function (registeredSelectedInsuranceCompanyId) {
        self.lifeInsuranceCompanies.forEach(function (lifeInsuranceCompany) {
          if (lifeInsuranceCompany.id === Number(registeredSelectedInsuranceCompanyId)) {
            inputs.selectedLifeInsuranceCompanyIds.push(registeredSelectedInsuranceCompanyId)
          }
        })
        self.nonLifeInsuranceCompanies.forEach(function (nonLifeInsuranceCompany) {
          if (nonLifeInsuranceCompany.id === Number(registeredSelectedInsuranceCompanyId)) {
            inputs.selectedNonLifeInsuranceCompanyIds.push(registeredSelectedInsuranceCompanyId)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  > .confirmation-modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    padding: 60px;
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    position: relative;
    overflow-y: scroll;
    max-height: 80vh;

    > .confirmation-modal-header {
      font-size: 28px;
      font-weight: 600;
      margin: 0 auto;
      padding-bottom: 15px;
      display: flex;
      justify-content: center;
    }

    > .confirmation-modal-body {
      margin-top: 20px;

      > .check-item {
        display: flex;
        padding: 20px;
        border-top: 2px solid #D9D9D9;

        &.-last {
          border-bottom: 2px solid #D9D9D9;
        }

        &.-column {
          flex-flow: column;

          > .checkbox-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            > .selected-insurance-company-group {
              padding: 7px 0;
              display: flex;
              align-items: flex-start;
              flex-basis: 50%;

              > .checkbox {
                height: 30px;
                width: 30px;
                cursor: pointer;
              }

              > .value {
                font-size: 17px;
                font-weight: 600;
                margin: 0;
                line-height: 32px;
                padding: 0 20px;
              }
            }

            > .no-selected {
              font-size: 17px;
              font-weight: 600;
              margin: 0;
              line-height: 32px;
              padding-left: 55px;
            }
          }
        }

        > .checkbox {
          height: 30px;
          width: 30px;
          cursor: pointer;
        }

        > .label {
          font-size: 18px;
          margin: 0;
          padding: 0 20px;
          line-height: 32px;
        }

        > .value {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
          line-height: 32px;
        }
      }

      > .buttons {
        padding-top: 30px;
        display: flex;
        justify-content: space-around;

        > .btn {
          font-size: 18px;
        }
      }
    }

    > .close {
      position: absolute;
      top: 1px;
      right: 8px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.disabled {
  background-color: #AAAAAA;
  border: 1px solid #AAAAAA;
  color: #7B7B7B;
}

.tabs {
  display: flex;
  justify-content: center;

  .tab {
    padding: 10px 20px;
    border: none;
    background: lightgray;
    cursor: pointer;
    width: 50%;
    border-radius: 10px 10px 0 0;
    text-align: center;
    color: #9A9A9A;

    &.active {
      background-color: white;
      color: #212529;
      font-weight: bold;
      border: 1px solid #ced4da;
      border-bottom: none;
    }
  }
}

.company-select-form {
  border: 1px solid #ced4da;
  border-top: none;
  padding: 13px 20px;

  > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > .checkbox-wrapper {
      flex-basis: 50%;
      padding: 10px 0;
      display: flex;
      align-items: flex-start;

      > .checkbox {
        cursor: pointer;
        height: 17px;
        width: 17px;
      }

      > .label {
        margin-bottom: 0;
        margin-left: 5px;
        line-height: 17px;
      }
    }
  }
}

.radio, .radio-label {
  cursor: pointer;
}
</style>
