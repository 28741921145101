<template>
  <div>
    <div class="form-group row" v-for="(insuranceCompanyConfirmationUrl, index) in insuranceCompanyConfirmationUrlList" :key="index">
      <label class="col-sm-2 col-form-label text-right">
        取扱保険会社確認URL{{ index + 1 }}<br>
        <small>※変更はできません</small>
      </label>
      <div class="col-sm-8 mt-2">
        <input type="text" class="form-control mb-1" :value="insuranceCompanyConfirmationUrl.name" readonly>
        <input type="text" class="form-control" :value="insuranceCompanyConfirmationUrl.url" readonly>
      </div>
      <button type="button" class="btn btn-danger h-25 mt-5" @click="deleteConfirmationUrl(insuranceCompanyConfirmationUrl, index)">
        削除
      </button>
    </div>
    <InputField
      :number-of-registered-confirmation-urls="insuranceCompanyConfirmationUrlList.length"
    >
    </InputField>
  </div>
</template>

<script>
import axios from 'axios'
import InputField from './InputField.vue'

export default {
  components: { InputField },

  props: {
    insuranceCompanyConfirmationUrls: {
      type: [Object, Array],
      required: false
    },
    insuranceCompanyConfirmationUrlDestroyEndpoint: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      insuranceCompanyConfirmationUrlList: this.insuranceCompanyConfirmationUrls
    }
  },

  methods: {
    // 引数に与えられた取扱保険会社確認URLを非同期で削除する
    deleteConfirmationUrl(insuranceCompanyConfirmationUrl, index) {
      const answer = confirm(insuranceCompanyConfirmationUrl.name + 'の取扱保険会社確認URLを削除してもよろしいでしょうか？')

      if (!answer) {
        alert('キャンセルしました。')
        return
      }

      axios.delete(this.insuranceCompanyConfirmationUrlDestroyEndpoint, {
        data: {
          id: insuranceCompanyConfirmationUrl.id,
          _token: this.csrfToken,
        }
      }).then((response) => {
        this.insuranceCompanyConfirmationUrlList.splice(index, 1)
        window.setTimeout(function() {
          alert(insuranceCompanyConfirmationUrl.name + 'の取扱保険会社確認URLを削除しました。')
        }, 500)
      }).catch((error) => {
        alert(error.response.data.message)
      })
    }
  },
}
</script>
